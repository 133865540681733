import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import loadLibrary from '../Loadable/library';

const Img = loadLibrary('gatsby-image');

export default function ImagePlaceholder(props) {
  const { latest, title } = props;
  const image = useStaticQuery(graphql`
    query placeholderQuery {
      default: file(relativePath: { eq: "images/placeholder.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 768, height: 768, layout: CONSTRAINED)
        }
      }
      latestnews: file(relativePath: { eq: "images/placeholder.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1200, height: 1200, layout: CONSTRAINED)
        }
      }
    }
  `);
  return (
    <Img
      image={
        latest
          ? image.latestnews.childImageSharp.gatsbyImageData
          : image.default.childImageSharp.gatsbyImageData
      }
      alt={title}
    />
  );
}
